import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { DEFAULT_ZIPCODE } from '../../../constants/default';
// import { ASAP, SCHEDULE } from '../../../constants/deliveryType';
// import { isNewHomePageApplicable } from '../../../constants/feature';
import * as WEBSITES from '../constants/website';
// import { useDeliveryDetails } from '../../../hooks/app';
import { useRouter } from 'next/router';

import RenderMinutes from './DeliveryTabs/RenderMinutes';
import RenderSeconds from './DeliveryTabs/RenderSeconds';
import RenderProdCount from './DeliveryTabs/RenderProdCount';
import { DEFAULT_ZIPCODE } from '../constants/default';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import notificationTypes from '../constants/modalNotificationConst';
import { isNewHomePageApplicable } from '../constants/feature';
import { useDeliveryDetails } from '../hooks/app';
import DeliveryTiming from './DeliveryTiming';
import DeliveryTimingSchedule from './DeliveryTimingSchedule';
import { pushNotification } from '../redux/slices/modalSlice';
import isMobile from '../helpers/isMobile';

const testOtherDays = date => {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].some(day => date.includes(day));
};

const DeliveryTypeToggle = ({
  currentTab,
  setTab,
  isAsapDisabled,
  isScheduleDisabled,
  className,
  asapProductsCount,
  scheduledProductsCount,
  // hideToggle,
  hideHeaderCount = false,
  setAsapCounterEnabled = () => { },
  setNoAsapSlots = () => { },
  displayForMobile
}) => {
  const [timeData, setTimeData] = React.useState({});
  const [remainingTime, setRemainingTime] = useState(-1);
  const [asapOpen, setasapOpen] = useState(false);
  const [tabLocal, setLocalTab] = useState(currentTab)
  const [hideDeliveryTabs, setHideDeliveryTabs] = useState(false);
  const notifications = useSelector(state => state.modal.notifications)

  const interval = useRef(null);
  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });

  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = useSelector(state => state.user)

  const {
    data: { asapReOpensAt, asapStartTime, asapEnabled, scheduleEnabled, noScheduleSlotsMessage, scheduleDeliveryMessage },
    isLoading
  } = useDeliveryDetails(false, true);
  const hideToggle = !asapEnabled || !scheduleEnabled;

  const dispatchRedux = useDispatch()
  const clearIntervals = () => {
    clearInterval(interval.current);
  };
  useEffect(() => {
    setLocalTab(currentTab)
  }, [currentTab])
  const showAvailableDelivery = () => {
    const scheduleSlotOpen = notifications.map(notification => notification.type).includes(notificationTypes.scheduleSlot);
    if (!scheduleSlotOpen) {
      dispatchRedux(pushNotification({
        type: notificationTypes.scheduleSlot,
        data: { noScheduleSlotsMessage, hideRadioButtons: !isNewHomePageApplicable }
      }))
    }
  };
  useEffect(() => {
    if (asapReOpensAt && asapStartTime) {
      const currentTimeArray = currentTime.split(':');
      const asapTimeArray = asapStartTime.split(':');
      if (currentTimeArray.length === 3 && asapTimeArray.length === 3) {
        let currentTimeHigherFlag = false;
        let hourDifference = 0;
        let minutesDifference = 0;
        let secondsDifference = 0;
        const diff = parseInt(24 - currentTimeArray[0]) + parseInt(asapTimeArray[0]);

        if (
          testOtherDays(asapReOpensAt) ||
          (asapReOpensAt.toLowerCase().includes('tomorrow') &&
            (diff > 24 || (diff === 24 && parseInt(asapTimeArray[1]) >= parseInt(currentTimeArray[1]))))
        ) {
          setTimeData({});
          setHideDeliveryTabs(true);
          setNoAsapSlots(true);
          return;
        }

        if (currentTimeArray[0] > asapTimeArray[0]) {
          hourDifference = 24 - parseFloat(currentTimeArray[0]) + parseFloat(asapTimeArray[0]);
          currentTimeHigherFlag = false;
        } else if (currentTimeArray[0] == asapTimeArray[0] && currentTimeArray[1] > asapTimeArray[1]) {
          hourDifference = 23 - parseFloat(currentTimeArray[0]) + parseFloat(asapTimeArray[0]);
          currentTimeHigherFlag = true;
        } else {
          hourDifference = parseFloat(asapTimeArray[0]) - parseFloat(currentTimeArray[0]);
        }

        if (currentTimeArray[1] > asapTimeArray[1]) {
          minutesDifference = parseFloat(60 - currentTimeArray[1]) + parseFloat(asapTimeArray[1]);
          hourDifference -= currentTimeHigherFlag ? 0 : 1;
        } else {
          minutesDifference = parseFloat(asapTimeArray[1]) - parseFloat(currentTimeArray[1]);
        }

        if (currentTimeArray[2] > asapTimeArray[2]) {
          secondsDifference = parseFloat(60 - currentTimeArray[2]) + parseFloat(asapTimeArray[2]);
          minutesDifference -= 1;
        } else {
          secondsDifference = parseFloat(asapTimeArray[2]) - parseFloat(currentTimeArray[2]);
        }
        setNoAsapSlots(false);
        setTimeData({
          hours: hourDifference,
          minutes: minutesDifference,
          seconds: secondsDifference
        });
        setAsapCounterEnabled(true);
        if (setTab) setTab(SCHEDULE);
        setLocalTab(SCHEDULE)
      }
    } else {
      setTimeData({});
      setAsapCounterEnabled(false);
      setNoAsapSlots(false);
      setHideDeliveryTabs(false);
    }
  }, [asapReOpensAt, asapStartTime]);

  const [deliveryTimeDetails] = useState({
    remainingSeconds: remainingTime % 60,
    remainingMinutes: parseInt(remainingTime / 60) % 60,
    remainingHours: parseInt(remainingTime / 3600)
  });

  const { remainingSeconds, remainingMinutes, remainingHours } = deliveryTimeDetails;

  useEffect(() => {
    (deliveryTimeDetails.remainingSeconds = remainingTime % 60),
      (deliveryTimeDetails.remainingMinutes = parseInt(remainingTime / 60) % 60),
      (deliveryTimeDetails.remainingHours = parseInt(remainingTime / 3600));
  }, [remainingTime]);

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeData || {};
    if (Object.keys(timeData).length) {
      setRemainingTime(hours * 3600 + minutes * 60 + seconds);
    }
  }, [timeData]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else if (remainingTime == 0) {
        setasapOpen(true);
        setTimeData({});
        setAsapCounterEnabled(false);
      }
    };
    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime, postcode]);
  const count = 0;

  const router = useRouter();

  const allowedRoutes = ['/', '/category/[slug]', '/shop/[slug]', '/brands/[slug]'];
  const shouldShowDeliveryTiming = allowedRoutes.some(route => {
    // Convert dynamic route patterns to regex
    const routePattern = route.replace(/\[.*?\]/g, '[^/]+');
    const regex = new RegExp(`^${routePattern}$`);
    return regex.test(router.pathname);
  });

  if (isNewHomePageApplicable && !asapOpen && timeData && Object.keys(timeData).length) {
    return (
      <div className={`asapcounter d-lg-flex border border-secondary-color relative ${displayForMobile ? 'special' : ""}`}>
        <div className="counterheader fs-12 fw-600 d-flex align-items-center justify-content-center">
          <div>
            <span className='text-[12px] md:text-[16px] font-[400]'>Super Fast</span>
            <div className="counter">
              <span>Back in</span> <b>{`${remainingHours > 0 ? `${remainingHours}h:` : '00h'}`}{' '}
                <RenderMinutes remainingMinutes={remainingMinutes} remainingHours={remainingHours} />
                <RenderSeconds remainingSeconds={remainingSeconds} remainingMinutes={remainingMinutes} /></b>
            </div>
          </div>
        </div>
        {!isScheduleDisabled ? (
          <div className="scheduled-tab fs-14 bold  height-100 bg-secondary-color !important">
            Scheduled <RenderProdCount productCount={scheduledProductsCount} isNewHomePageApplicable={isNewHomePageApplicable} />{' '}
            <div onClick={() => tabLocal == SCHEDULE && showAvailableDelivery()} className={` mt-1 font-normal ${tabLocal == SCHEDULE ? 'text-white' : 'text-secondary-color'} !important text-[12px]`}>
              <DeliveryTimingSchedule onlyText currentTab={tabLocal} />
            </div>
          </div>
        ) : null}
        {shouldShowDeliveryTiming && (
          <div className='mt-2 hidden md:block absolute bottom-0 w-full left-0 py-1 transform translate-y-[100%] text-center'>
            {
              currentTab === ASAP ? <DeliveryTiming currentTab={"asap"} onlyText fullText /> :
                <DeliveryTimingSchedule onlyText currentTab={currentTab} fullText />
            }
          </div>
        )}
      </div>
    );
  }
  // if (hideToggle || hideDeliveryTabs) return null;
  return (
    <>
      <div className={`md:relative border flex border-secondary-color delivery-type-toggle${isNewHomePageApplicable ? '-v2' : ''} ${className} rounded-[10px] !important`}>

        <button
          type="button"
          data-testid="#asap-tab"
          className={`btn lg:min-w-[200px] ${tabLocal == ASAP ? 'd-selected' : 'not-selected'} ${!asapEnabled && !asapReOpensAt ? "!hidden" : ""}`}
          onClick={() => {
            const t = setTimeout(() => {
              setTab(ASAP);
              clearTimeout(t)
            }, 100)
            setLocalTab(ASAP)
            window.scrollTo(0, 0);
          }}
          disabled={!asapEnabled || isLoading}
        >
          {WEBSITES.GRASSDOOR || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'Super Fast ' : 'Express'}
          {!isLoading ? <RenderProdCount productCount={asapProductsCount} /> : null}
          <div className={`mt-2 font-normal ${tabLocal == ASAP ? 'text-white' : 'text-secondary-color'} !important text-[12px]`}>
            <DeliveryTiming onlyText currentTab={"asap"} />
          </div>
        </button>
        <button
          type="button"
          data-testid="#schedule-tab"
          className={`btn lg:min-w-[200px] schedule-tab ${tabLocal == SCHEDULE ? 'd-selected' : 'not-selected'} ${!asapReOpensAt && !asapEnabled ? "only-schedule" : ""}`}
          onClick={() => {
            setLocalTab(SCHEDULE)
            window.scrollTo(0, 0);
            const t = setTimeout(() => {
              setTab(SCHEDULE);
              clearTimeout(t)
            }, 100)
          }}
          disabled={!!isScheduleDisabled || isLoading}
        >
          Scheduled <RenderProdCount productCount={scheduledProductsCount} />
          <div onClick={() => tabLocal == SCHEDULE && showAvailableDelivery()} className={`mt-2 font-normal ${tabLocal == SCHEDULE ? 'text-white' : 'text-black'} !important text-[12px] delivery-timing-text`}>
            <DeliveryTimingSchedule onlyText currentTab={tabLocal} />
          </div>
        </button>
        {!isMobile() && shouldShowDeliveryTiming && (
          <div className='mt-2 hidden md:block absolute bottom-0 w-full left-0 py-1 transform translate-y-[100%] text-center'>
            {
              currentTab === ASAP ? <DeliveryTiming currentTab={"asap"} onlyText fullText /> :
                <DeliveryTimingSchedule onlyText currentTab={currentTab} fullText />
            }
          </div>
        )}
      </div>
    </>
  );
};

export default DeliveryTypeToggle;
